/* You can add global styles to this file, and also import other style files */


html {
  font-size: small;
}

body {
  background: #102E4A;
}

.rejectButtonStyleClass {
  background: red !important;
}

.displayNone {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  margin-right: 10px;
}

div::-webkit-scrollbar {
  width: 8px;
  margin-right: 10px;
  border-radius: 10px!important;
}

div::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px!important;
}

::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track-piece {
  background: rgb(160, 160, 160);
  border-radius: 10px!important;
}
::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216);
  border-radius: 10px!important;
}

.badge {
  color: white !important;
  cursor: context-menu;
}

.logo-dg {
  text-align: center;
  p {
    color: #fff;
    font-weight: 500;
  }
  img {
    transition: transform 0.5s ease-out;
  }
  img:hover {
    transform: scale(1.06);
  }
}